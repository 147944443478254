

.team-listing-container {

}

.team-member-card {

  margin-bottom: 40px;
  align-content: space-between;

  &__image {
    border: 2px solid $coal;
    border-radius: 7px;
    filter: grayscale(100%) opacity(90%);
    margin-bottom: 15px;

    &--no-image {

    }
  }

  &__detail {
    font-size: $font-size-medium;

    &__name {
      font-weight: 500;
      padding-right: 0.4em;
      margin-right: 0.4em;
      border-right: 2px solid $coal;
      display: inline;
    }
    &__title {
      display: inline;
    }
  }
}
