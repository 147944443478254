.image-carousel-block {

  &[data-has-padding="True"] {
    padding: 4rem 0;
  }


  .carousel-item {

    border: 5px solid $white;
    border-width: 0 5px;

    &.active {
      //border: 0;
    }


    h2 {
      position: absolute;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;
      font-family: $font-family-text;
      margin: 0;
      padding: 1em 2em;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(to top, transparentize($coal, 0), transparentize($coal, 1));
      color: $white;
    }
  }

  @each $color in $callout-colors {
    &.background-#{nth($color,1)} {
      background-color: nth($color, 2);
      color: nth($color, 3);

      .carousel-item {
        border-color: nth($color, 2);
      }

      .carousel-indicators .round-indicator.dark {
        background-color: nth($color, 3);
      }

      .carousel-controls {
        button {
          filter: invert(100%);
        }
      }
    }
  }

}
