
// A general use class for styling wagtail richtext content
.rich-text-area {
  clear: both; // This helps get round floating images

  h2, h3 {
    clear: both;
    margin: 1.5em 0 1em;
    text-transform: uppercase;
    font-family: $font-family-heading;
    font-weight: $font-weight-semi;
    font-size: $font-size-bigger;
    color: $york;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    color: inherit;
    clear: both;
    margin: 2em 0 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    font-size: 1.3em;
    margin: 2em 0 0;
    font-family: $font-family-heading;
    font-weight: $font-weight-medium;
    text-transform: uppercase;

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Horizontal Rule
  hr {
    border-color: $york;
    border-width: 3px;
    margin: 2em 3em;
  }

  p {
    margin: 0.5em 0 1.2em;
    font-family: $font-family-text;
    font-size: $font-size-lead;


    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    b, strong {

    }

    i, em {

    }

    a {

    }
  }

  ul, ol {
    margin: 1em 0 1.2em;
    font-size: $font-size-lead;

    li {
      margin-bottom: 0.5em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    li {

    }
  }

  .richtext-image {
    clear: both;
    margin: 0 0 2em 0;
    border: 1px solid $coal;

    &.left {
      float: left;
      margin-right: 2em;
    }

    &.right {
      float: right;
      margin-left: 2em;
    }

    &.full-width {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  // Embeds
  iframe {
    margin: 1em 0;
    width: 100%;
    height: 600px;
  }
}

// The streamfield block of the same name
.rich-text-block {
  clear: both;
  padding: 3rem 0;

  @each $color in $callout-colors {
    &.background-#{nth($color,1)} {
      background-color: nth($color, 2);
      color: nth($color, 3);
    }
  }
}

@each $color in $callout-colors {
  .heading-block.background-#{nth($color,1)} + .rich-text-block.background-#{nth($color,1)} {
   padding-top: 0;
  }
}
