.list-content-with-image-block {
    h2 {
        margin-top: 80px;
    }

    .card {
        border: none;
        text-align: center;
        @include media-breakpoint-up(sm) { 
            text-align: left;
        }
        .card-title {
            font-weight: 500;
            font-size: $font-size-lead;
        }
        .card-text {
            font-size: $font-size-lead;
        }
    }
}