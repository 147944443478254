.news-alert-bar {
  background: $cloud;
  min-height: 70px;
  padding: 10px;
  position: relative;

  .lead {
    font-family: $font-family-heading;
  }

  &__title {
    border-right: 2px solid $coal;
    padding-right: 16px;
    text-align: right;
    letter-spacing: 0.2px;




    span {
      font-weight: 500;
    }

    @include media-breakpoint-down(sm) {
      display: none;
      text-align: left;

    }

  }
  &__content {
    padding-left: 16px;
    max-width: 620px;
    
    @include media-breakpoint-down(sm) {
      max-width: 370px;
      padding-left: 12px;
    }

    a {
      color: $coal;
      text-decoration: underline;

      &:hover {
        color: lighten($coal,30%);
      }
    }
  }
  .btn-close {
    background: url("../images/circle-close.svg") no-repeat center;
    background-size: contain;
    width: 40px;
    height: 40px;
    opacity: 1;
    box-shadow: none;

    @include media-breakpoint-down(sm) {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

  }
}
