.image-block {
    padding: 2em 0;
    position: relative;

    &__wrapper {
        position: relative;
    }

    img {
        max-width: 100%;
        height: auto;
    }


    &__title {
        position: absolute;
        font-size: $font-size-medium;
        bottom: 0;
        right: 0; left: 0;
        color: $white;
        margin: 0;
        padding: 1em;
        z-index: 10;
        background-image: linear-gradient(to top, transparentize($coal, 0), transparentize($coal, 1));
    }

    @each $color in $callout-colors {
        &.background-#{nth($color,1)} {
            background-color: nth($color, 2);
            color: nth($color, 3);
        }
    }
}