.video-block {
    padding: 2em 0;

    video {
        background-color: $coal;
    }

    @each $color in $callout-colors {
        &.background-#{nth($color,1)} {
            background-color: nth($color, 2);
            color: nth($color, 3);
        }
    }
}