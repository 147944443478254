// 1 to 1 to the design system color names
$coal: #2b2b2b;
$york: #f2a901;
$off-white: #f1f1f1;
$evergreen: #29a800;
$tomatoes: #d50000;
$white: #ffffff;
$light-grey: #cfcfcf;
$elephant: #939393;
$cloud: #f8f8f8;
$cornflower: #00bdde;
$violet: #b522bb;
$black: #000000;
$grey: #484848;
$vivid-blue: #014bdc;
$clay: #e6b48a;
$pine: #8db181;
$sky: #b5cbd7;

// Setting Bootstrap Primary Colour
$primary: $york;



