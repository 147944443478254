// All base styles, Headings, Paragraphs etc
$font-size-base: 18px; // Base font size of design seems to be 18.
$font-size-medium: 1.222em; // 18px = 22px
$font-size-lead: 1.333em; // With an 18px base = 24px
$font-size-small: 0.778em; // 18px = 14px
$font-size-smaller: 0.888em; // 18px = 16px;
$font-size-big: 1.777em; // 18px = 32px;
$font-size-bigger: 2em; // 18px = 36px
$font-size-biggest: 2.666em; // 18px = 48px
$font-size-h1: 3.333em; // 18px = 60px
$font-size-h2: 2.222em; // 18px = 40px
$font-family-text: "Barlow";
$font-family-heading: "Barlow Condensed";

$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-semi: 600;
$font-weight-regular: 400;



$card-colors: ('clay', $clay),('sky', $sky),('cloud', $cloud),('pine', $pine);
$callout-colors: ('coal', $coal, $white),('off-white', $off-white, $black);

$transition-fast: 150ms;
$transition-smooth: 500ms;
$transition-slow: 800ms;



body {
  font-size: $font-size-base;
  font-family: $font-family-text;
}


// Watch out when adjusting these root level tag styles, they may change other components.
h1 {
  font-family: $font-family-heading;
  font-size: $font-size-h1;
  font-weight: 500;
  text-transform: uppercase;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-bigger;
  }
}

h2 {
  font-size: $font-size-h2;
  font-weight: 500;
  font-family: $font-family-heading;
  margin-bottom: 1em;
}

h3 {
  font-size: $font-size-bigger;
  font-family: $font-family-heading;
  text-transform: uppercase;
  font-weight: $font-weight-semi;
  margin: 2.25em 0 1.6em 0;
}

p {
  &.lead {
    font-size: $font-size-lead;
    font-weight: 500;
  }
}

// Common page header container
.page-header-container {

  padding-top: 115px;
  padding-bottom: 50px;

  @include media-breakpoint-down(sm) {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  h1 {
    margin: 0;
    padding: 0;
  }
}


.dropdown.dropdown--gemfair {
  .btn.dropdown-toggle {
    background: $white;
    border: 2px solid $coal;
    color: $coal;
    width: 100%;
    text-align: left;
    position: relative;

    &::after {
      position: absolute;
      right: 15px;
      top: 20px;
    }
  }
}


.expanding-heading {
  background: $cloud;
  font-size: $font-size-bigger;
  font-weight: 400;
  padding: 30px 50px;
  padding-right: 120px;
  border-radius: 7px;
  transition: background-color 150ms;
  background-image: url("../images/circle-minus.svg");
  background-repeat: no-repeat;
  background-position: right 50px center;
  margin: 0;

  &.collapsed {
    background-image: url("../images/circle-plus.svg");
  }

  &:hover {
    background-color: darken($cloud, 10%);
  }
}


.accordion-list {
  &__item {
    border-radius: 7px;
    margin-bottom: 22px;

    h3 {
      @extend .expanding-heading;
    }

    .collapse, .collapsing {
      background: $cloud;
      border-radius: 7px;
    }

    &__content {
      padding: 30px 50px;
    }
  }
}

.btn-download {
  background-image: url("../images/download-icon.svg");
  background-repeat: no-repeat;
  background-size: auto 1em;
  background-position: right 0.75em center;
  padding-right: 2.3em;

  &::after {

  }
}

table td  {
  padding:10px;
}
