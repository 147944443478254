$tabs-content-padding:120px;
$tabs-content-padding-small: 30px;
.tabs-content-block {
    &__text {
        font-size: $font-size-lead;
    }
    .nav {
        &.nav-tabs {
            padding: 0 $tabs-content-padding-small;
            background-color: $off-white;
            border-radius: 7px;
            @include media-breakpoint-up(sm) { 
                padding: 0 $tabs-content-padding;
            }
        }
        .nav-link {
            background-color: $off-white;
            color: $coal;
            padding: 30px 0;
            border:none;
            font-weight: 600;
            text-transform: uppercase;
            &.active {
                border:none;
                border-bottom: 3px solid $coal;
            }
        }
    }
    .tab-content {
        border-radius: 7px;
        padding: 24px $tabs-content-padding-small;
        background-color: $off-white;
        margin:20px 0;
        @include media-breakpoint-up(sm) { 
            padding: 44px $tabs-content-padding;
        }
    }
    &__video, &__image {
       border-radius: 7px;
    }
}