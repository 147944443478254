.callout-content-block {
    background-color: $white;
    @each $color, $rgb, $textcolor in $callout-colors {
        &[data-background-color=#{$color}] {
            background-color: $rgb;
            .callout-content-block__text,h2 {
                color: $textcolor;
            }
        }
    }
    .rich-text-area {
      font-size: 1.2em;
    }
    .row {
        padding: 80px 0;
    }
}
