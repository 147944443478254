.faqs-block {
padding: 4rem 0;

  .faq-carousel {

    &__faq {
      height: 100%;
      padding-right: 1em;

      h3 {
        @extend .expanding-heading;

        display: flex;

        font-family: $font-family-text;
        font-size: $font-size-lead;
        text-transform: none;
        height: 100%;

        background-color: $light-grey;

        &.collapsed {
          background-color: $cloud;
        }
      }
    }

    &__content {

      background-color: $cloud;
      margin-top: 1em;
      border-radius: 7px;
      padding: 30px 50px;

      &__section {

      }

    }

  }


}
