.quote-block {
  //margin: 2rem 0;

  blockquote {
    margin: 0;

    > p {
      font-size: $font-size-h2;
      font-weight: $font-weight-bold;

      &:before, &:after {
        content: '"';
      }
    }

    cite {
      font-style: normal;

      p {
        font-size: $font-size-h2;

        &::before {
          content: '-';
        }

        .name {

        }
      }
    }
  }



  @each $color in $callout-colors {
    &[data-background-color=#{nth($color,1)}] {
      padding: 3rem;
      background-color: nth($color, 2);
      color: nth($color, 3);
    }
  }

}
