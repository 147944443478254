.documents-accordion-block {
    h2 {
        color: $york;
        font-weight: 400;
    }
    
    &__title {
        display: block;
        font-weight: 400;
        color: $black;
        margin-top: 1.4em;
        font-size: $font-size-lead;
        text-decoration: none;
        transition: color $transition-fast;

        &:hover {
            color: $york;
        }

        &:first-child {
            margin-top: 0;
        }

        span {
            transform: translateY(4px);
        }
    }

    &__tags {
        list-style: none;
        padding: 1em 0 0 0;

        &__tag {
            background: $white;
            display: inline-block;
            padding: 8px;
            font-size: 16px;
            font-weight: 500;
            border: 2px solid $coal;
            border-radius: 6px;
            width: 100px;
            text-align: center;
        }
    }

    .accordion-list {
        h3 {
            text-transform: uppercase;
            font-weight: 500;
        }
    }

}