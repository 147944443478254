.gf-footer {
    background-color: $coal;

    footer {
        padding: 90px 0;
        div,.nav-link {
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $white;
            font-weight: 600;
        }
        p {
            font-size: $font-size-small;
            color: $white;
            font-weight: 600;
        }
        ul {
            .nav-item {
                margin-right: 50px;
            }
            @include media-breakpoint-down(sm) {
                align-items: center;
                .nav-item {
                    margin-right: 0;
                }
            }
        }

        h3 {
            font-size: 1.56em; //~30px
            text-transform: none;
            margin: 0;
        }

        button {
            font-size: $font-size-base;
            font-weight: 600;
            letter-spacing: 0.75px;
            padding: 10px;
        }
    }
}
