#homeCarousel {
  .carousel-caption {
    
    height: 100%;
    display: flex;
    flex-direction: column;

    text-align: left;
    justify-content: center;
    align-items: flex-start;

    h2 {
      font-size: $font-size-h1;
      margin-bottom: 0.5em;
      line-height: 1.5em;

      @include media-breakpoint-down(sm) {
        font-size: $font-size-big;
      }
    }

    p {
      font-size: $font-size-big;
    }


    @include media-breakpoint-up(sm) {


    }
  }

  .carousel-indicators {
    bottom: 5%;
    justify-content: flex-start;
    align-items: center;

    .round-indicator {
      border-radius: 50%;
      width: 10px;
      height: 10px;

      &.active {
        width: 14px;
        height: 14px;
      }
    }

    .carousel-control-prev {
      left: 0px;
      height: 100%;
      width: 30px;
      opacity: 1;
      background-color: transparent;
    }

    .carousel-control-prev-icon {
      background-image: url("/gemfair_website_wagtail/static/images/carousel_indicator_icon.png");
      transform: rotate(180deg);
    }

    .carousel-control-next {
      left: 50px;
      height: 100%;
      width: 30px;
      opacity: 1;
      background-color: transparent;
    }

    .carousel-control-next-icon {
      background-image: url("/gemfair_website_wagtail/static/images/carousel_indicator_icon.png");
    }

    .indicators-wrapper {
      margin-top: -15px;
    }
  }

}

.sticky-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  pointer-events: none;
  padding: 0 0 2em 0;

  #scrollToTop {
    background: url('../images/icon_backtotop.svg') no-repeat 100%;
    width: 52px;
    height: 52px;
    cursor: pointer;
    pointer-events: all;
    margin-left: 100%;
    opacity: 0;
    transition: opacity $transition-smooth;
    border: 0;

    &.active {
      opacity: 1;
    }
  }
}


