.content-with-inline-image-block {
    .card-body {
        padding: 76px 140px;
        @include media-breakpoint-down(sm) {
            padding: 30px 20px;
        }

        & > * {
            line-height: 36px;
            font-family: $font-family-heading;
        }
    }
    .card {
        background-color: $white;
        @each $color in $card-colors {
            &[data-background-color=#{nth($color,1)}] {
                background-color: nth($color, 2);
                border:none;
            }
        }
    }
}