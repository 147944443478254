.full-width-image-block {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 8em;
    background-image: linear-gradient(to top, transparentize($coal, 0), transparentize($coal, 1));
  }


  .container {
    height: 100%;
    align-items: flex-end;
    position: relative;
    z-index: 10;
  }

  &__title {
    position: absolute;
    font-size: $font-size-medium;
    bottom: 0;
    right: 0; left: 0;
    color: $white;
    margin: 0;
    padding: 1em 0 2em;
  }

  &__image {
    height: 680px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
