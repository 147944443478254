.gemfair-way-header {
  .container {
    position: relative;

    .row {
      position: relative;
      z-index: 2;
      background-color: rgba($black, 0.2);
    }
  }

  &__background-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      width: 100%;
      height: auto;
    }

    video {
      min-width: 100%;
      min-height: 100%;
      height: 600px;
      object-fit: fill;
      object-position: center;
    }
  }

  &__content {
    padding-top: 160px;
    padding-bottom: 160px;
    flex-direction: row;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    h2,
    h3,
    h4,
    p,
    li {
      color: $white;
    }

    p {
      font-size: $font-size-lead;
    }

    h2 {
      font-size: $font-size-h1;
      margin-bottom: 0.5em;

      @include media-breakpoint-down(sm) {
        font-size: $font-size-big;
      }
    }
  }
}

.context-card {
  border: 0;

  &__image {
    border-radius: 7px;
  }

  &__text {
    font-size: $font-size-lead;
  }
}

.gemfair-way-sections {
  padding-top: 100px;

  &__navigation {
    .nav-item {
      margin: 0 10px 10px 0;

      .nav-link {
        background-color: $coal;
        border: 7px solid $coal;
        border-width: 0 0 9px 0;
        border-radius: 7px 7px 7px 7px;
        font-size: $font-size-big;
        font-family: $font-family-heading;
        text-transform: uppercase;
        color: $white;
        transition: border-color $transition-smooth,
          border-radius $transition-smooth;
        padding: 40px 20px 31px 20px;

        &:hover {
          border-color: transparentize($york, 0.2);
        }

        &.active {
          border-color: $york;
          border-radius: 7px 7px 0px 0px;
        }
      }
    }
  }

  .next-up-navigation {
    padding: 3em 0 4em 0;

    h3 {
      margin: 0 0 0.25em 0;
    }

    p {
      font-size: $font-size-lead;
    }

    .btn {
      position: relative;
      padding-right: 2em;

      &::after {
        content: "";
        position: absolute;
        right: 0.5em;
        bottom: 0.5em;
        background-image: url("../images/arrow-white.svg");
        background-repeat: no-repeat;
        background-position: center center;
        width: 1.2em;
        height: 1.2em;
        display: inline-block;
      }
    }
  }

  .feedback-section {
    padding: $font-size-h1 0;

    h2 {
      margin: 0;
    }

    a {
      color: $black;
      text-decoration: underline;
      font-style: italic;
    }
  }
}
