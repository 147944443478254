.carousel.carousel-multi {

  .carousel-item {
    background: $white;
    max-height: 750px;

    &__inner {
      padding-right: 1.6em !important;
    }

    &__wrapper {
      //display: flex;
      //flex-direction: column;
      //align-items: stretch;
    }
  }

  @media (max-width: 767px) {
    .carousel-inner .carousel-item > div {
      display: none;
    }
    .carousel-inner .carousel-item > div:first-child {
      display: block;
    }
  }

  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    display: flex;
  }

  /* medium and up screens */
  @media (min-width: 768px) {

    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(50%);
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
      transform: translateX(-50%);
    }
  }

  .carousel-inner .carousel-item-end,
  .carousel-inner .carousel-item-start {
    transform: translateX(0);
  }

}

.carousel {
  .carousel-controls-wrapper {
    display: flex;
    margin-top: 20px;

    .carousel-indicators {
      position: relative;
      align-items: center;
      justify-content: flex-start;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      height: 40px;

      .round-indicator {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: $black;
        opacity: 1;
        margin-right: 17px;
        transition: width $transition-fast, height $transition-fast;

        &.active {
          width: 14px;
          height: 14px;
        }
      }
    }

    .carousel-controls {
      position: relative;
      display: flex;
      margin-left: 50px;

      .carousel-control-prev, .carousel-control-next {
        position: static;
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        border-radius: 15px;
        background-color: $coal;
        margin-right: 10px;
        opacity: 1;
      }

      .carousel-control-prev-icon, .carousel-control-next-icon {
        height: 12px;
        opacity: 1;
        background-image: url("/gemfair_website_wagtail/static/images/angle_white.svg");
      }

      .carousel-control-prev-icon {
        transform: rotate(180deg);
      }

    }
  }
}


