.stats-block {
  margin: 3em 0 2em;

  position: relative;

  .container {
    position: relative;
  }

  &__frame {
    content: '';
    border: 2px solid $white;
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    pointer-events: none;
  }


  .stat {

    border: 2px solid $light-grey;
    border-width: 0 2px 2px 0;
    padding: 4em;
    height: 100%;

    @include media-breakpoint-down(sm) {
      padding: 2em;
    }


    h2 {
      color: $york;
      text-transform: uppercase;
      font-size: $font-size-big;
    }

    .stat__figure {
      font-size: $font-size-big;
      font-weight: $font-weight-semi;
      text-transform: initial;
      margin: 0;
      padding: 0;
    }
    .stat__title {
      padding: 0;
      font-size: $font-size-lead;
    }
  }
}
