

@import 'overrides';
@import '~bootstrap/scss/bootstrap';
@import 'common';

.text-body-primary {
  color: $black;
}

.text-body-secondary {
  color: $white;
}


// project specific CSS goes here

///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

#gemfair {
  @import "carousel";
  @import "header";
  @import "news_index";
  @import "news_page";
  @import "news_alert_bar";
  @import "footer";
  @import "team";
  @import "home";
  @import "streamfields/rich-text";
  @import "streamfields/heading_block";
  @import "streamfields/content_with_inline_image";
  @import "streamfields/common_content_block";
  @import "streamfields/callout_content_block";
  @import "streamfields/quotes_carousel_block";
  @import "streamfields/list_content_with_image_block";
  @import "streamfields/tabs_content_block";
  @import "streamfields/documents_accordion_block";
  @import "streamfields/sideways_expanding_content_block";
  @import "streamfields/full_width_image_block";
  @import "streamfields/image_carousel_block";
  @import "streamfields/stats_block";
  @import "streamfields/quote_block";
  @import "streamfields/faqs_block";
  @import "streamfields/video_block";
  @import "streamfields/image_block";
  @import "streamfields/whitespace_block";
  @import "gemfair_way";
}
