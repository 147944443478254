.heading-block {
  $root-name: &;

  padding: calc(#{$font-size-h2} * 1.5) 0 $font-size-h2 0;

  &__wrapper {
    display: flex;
    flex-direction: row;

    h2 {
      margin: 0;
    }

    &__indicator {
      font-family: $font-family-text;
      font-size: $font-size-lead;
      background-color: $york;
      height: $font-size-h2;
      width: $font-size-h2;
      border-radius: calc(#{$font-size-h2} / 2);
      text-align: center;
      line-height: calc(#{$font-size-h2} - 2px);
      margin-right: 0.75em;
    }
  }


  &:nth-of-type(1n) {
    
    .heading-block__wrapper {

      &__indicator {
        background-color: $york;
      }
    }
  }

  &:nth-of-type(2n) {
    
    .heading-block__wrapper {

      &__indicator {
        background-color: $pine;
      }
    }
  }

  &:nth-of-type(3n + 3) {
    
    .heading-block__wrapper {

      &__indicator {
        background-color: $clay;
      }
    }
  }

  &:nth-of-type(4n + 4) {
    
    .heading-block__wrapper {

      &__indicator {
        background-color: $york;
      }
    }
  }

  @each $color in $callout-colors {
    &.background-#{nth($color,1)} {
      background-color: nth($color, 2);
      color: nth($color, 3);

      h2 {
        color: inherit;
      }

      @at-root
      #{$root-name}__wrapper__indicator {
        color: nth($color, 3);
      }

    }
  }

}
