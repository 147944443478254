// Header
header {
    .navbar.navbar-expand-lg {
        background-color: $white;
        padding: 39px 0;
        text-transform: uppercase;

        .navbar-brand {
          margin-right: 3em;
          max-width: 50%;
        }

        .navbar-nav {
            @include media-breakpoint-down(sm) {
                margin-top: 1em;
            }
        }

        .nav-item {
            font-weight: 400;
            font-family: $font-family-heading;
            font-size: $font-size-smaller;
            color: $black;
            margin-right: 10px;
            padding: 2px;
            border-radius: 23px;
            transition: background-color $transition-smooth;

            @include media-breakpoint-down(sm) {
                padding-left: 1em;
                padding-right: 1em;
                text-align: center;
            }

        }
        .nav-link {
            color: $black;
        }
        .nav-item.active, .nav-item:hover  {
            background-color: $york;
        }
    }
}
