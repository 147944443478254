.quotes-carousel-block {
    background-color: transparent;
    padding: 80px 0;

    .card {
        background-color: $coal;
        margin-right: 1em;

        @include media-breakpoint-up(sm) {
            padding: 42px 44px;
        }
    }
    .card-body {
        font-size: $font-size-lead;
        letter-spacing: 0.2px;
    }
    .card-text {
        color: $white;
    }
    // Carousel override
    .carousel-indicators {
        top: 100%;
        margin-left: 0;
    }
}
