

.news-listing-container {

  .news-index-post {

    border-left: 2px solid #cfcfcf;
    padding: 0 30px 0 30px;
    margin-bottom: 50px;

    &__thumbnail {
      border-radius: 7px;
      margin: 0 0 8px;
      width: 100%;
    }

    &__tags {
      font-size: $font-size-small;
      margin: 0 0 0.857em;

      .tag-link {
        color: $coal;
      }
    }

    &__title {
      font-size: $font-size-lead;
      font-weight: 500;
      margin: 0;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          color: lighten($coal, 20%);
        }
      }
    }

    &__image-and-source {
      position: relative;
      margin-bottom: 14px;
      margin-right: 20px;

      &--has-image {
        .news-index-post__source {
          position: absolute;
          top: 13px;
          left: 9px;
        }
      }
    }

    &__source {
      display: inline-block;
      padding: 8px;
      background-color: $coal;
      font-weight: 500;
      color: $white;
      text-transform: uppercase;
    }

    &--featured {

      .news-index-post__image-and-source {
        position: relative;
      }

      .news-index-post__image-and-source--has-image .news-index-post__source {
        position: absolute;
        top: 13px;
        left: 9px;
        background-color: $white;
        color: $coal;
      }

      &:first-child {
        .news-index-post__image-and-source--has-image .news-index-post__source {
          font-size: $font-size-bigger;
        }
      }
    }

  }


}
