// .sideways-expanding-content {
//   display: flex;
//   flex-wrap: nowrap;

//     .accordion-item {
//         border: none;
//         max-width: 600px;
//     }
//     .card {
//         background-color: $white;
//         border-radius: 7px;
//         border: 2px solid $coal;
//         min-height: 600px;
//         min-width: 220px;
//         @include media-breakpoint-down(sm) {
//             min-height: 100%;
//         }


//         .card-title {
//             font-weight: 600;
//             text-transform: uppercase;

//             h3 {
//                 font-size: 1.55em;
//             }
//             @include media-breakpoint-down(sm) {
//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 h3 {
//                     margin-left:10px;
//                 }
//             }
//         }
//         .card-body {
//             padding: 50px 24px;
//         }
//         .card-image {
//             display: inline;
//             width: 0;
//             opacity: 0;
//             transition: opacity 0.5s, display 0.5s ease-in;
//         }
//         .card-text {
//             transition: width 0.5s, height 0.1s, transform 0.5s;
//         }
//         &:not(.collapsed) {
//             .card-body {
//                 padding: 50px 24px;

//                 @include media-breakpoint-up(sm) {
//                     padding: 50px 100px;
//                 }
//             }
//             .card-image {
//                 display: block;
//                 opacity: 1;
//                 width: auto;
//             }
//         }
//         .card-text__wrapper {
//             min-width:330px;
//             overflow: hidden;

//             @include media-breakpoint-up(sm) {
//               min-width: 200px;
//             }
//         }
//     }
// }

.sideways-expanding-content {
    display: flex;
    width: 100%;
    padding: 3em 0;

    &.uninitialized * {
        transition: none !important;
    }

    .card {
        min-height: 300px;
        box-sizing: border-box;
        padding: 0;
        overflow: hidden;
        float: left;
        align-items: flex-start;
        transition: width 0.2s;
        border-radius:3px;
        font-size: 1em;

        @each $color in $card-colors {
            &[data-background-color=#{nth($color,1)}] {
                background-color: nth($color, 2);
            }
        }
        .card-head {
            position: relative;
            margin-bottom: 2em;
            display: block;
        }

        .card-image {
            position: absolute;
            right: 0;
            top: 0;
            opacity: 1;
            transition: opacity 200ms 350ms;
        }

        .card-body {
            display: block;
            padding: 56px;
            transition: padding 250ms;
            height: auto;
            width: 100%;
        }

        .card-number {
            font-size: $font-size-h1;
        }

        .card-title {
            font-weight: 600;
            font-family: $font-family-heading;
            font-size: $font-size-big;
            text-transform: uppercase;
            margin-bottom: 0.5em;
            overflow-wrap: normal;
        }

        .card-text {
            opacity: 1;
            transition: opacity 150ms 250ms;

            .rich-text-area {
                font-size: 1.5em;
            }

        }
    }

    .expand {
        width: 55%;

        .card-image {
            opacity: 1;
        }

        .card-text {
            opacity: 1;
        }
    }
    .unset{
        width: 16%;
        color: black !important;

        .card-body {
            padding: 56px 21px;

        }
        .card-text {
            opacity: 0;
        }
    }

    .small {
        width: 22.5%;

        .card-body {
            padding: 56px 21px;
        }

        .card-text {
            opacity: 0;
            transition: opacity 100ms;
        }

        .card-image {
            opacity: 0;
            transition: opacity 100ms;
        }
    }

}


